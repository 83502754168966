
import { defineComponent } from '@vue/composition-api'

import BoardingEquipment from '@/views/persons/views/personProfile/components/lowerPersonProfile/components/equipment/components/BoardingEquipment.vue'

import { BOARDING_TYPE } from '@/views/persons/views/personProfile/types'
import { useGetEquipmentList } from '@/api/equipment'

export default defineComponent({
  name: 'Equipment',
  components: {
    BoardingEquipment,
  },
  setup: (_, { root }) => {
    const { exec: getEquipmentList, data: equipmentList } = useGetEquipmentList()

    async function init() {
      await getEquipmentList({ params: { personIds: root.$route.params.id } })
    }
    init()

    return {
      constants: {
        BOARDING_TYPE,
      },
      state: {
        equipmentList,
      },
      functions: {
        init,
      },
    }
  },
})
