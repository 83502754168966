var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "text-subtitle-1 font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.onboarding.title')) + " ")]), _c('BoardingEquipment', {
    attrs: {
      "boarding-type": _vm.constants.BOARDING_TYPE.ONBOARDING,
      "equipment-list": _vm.state.equipmentList.value
    },
    on: {
      "reload": function reload($event) {
        return _vm.functions.init();
      }
    }
  })], 1), _c('v-col', [_c('h3', {
    staticClass: "text-subtitle-1 font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.offboarding.title')) + " ")]), _c('BoardingEquipment', {
    attrs: {
      "boarding-type": _vm.constants.BOARDING_TYPE.OFFBOARDING,
      "equipment-list": _vm.state.equipmentList.value
    },
    on: {
      "reload": function reload($event) {
        return _vm.functions.init();
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }