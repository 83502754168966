
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiPaperclip, mdiDelete, mdiNotebookEditOutline, mdiTextBoxCheckOutline } from '@mdi/js'

import { useAuthGetters, useNotify } from '@/store'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'

import { useDeleteEquipment, useUpdateEquipment } from '@/api/equipment'

import { handleError } from '@/utils/handleError'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { EquipmentOutput } from '@/api/types/equipment'
import { Rights } from '@/api/types/right'
import { BOARDING_TYPE, ENTITY_NAME } from '@/views/persons/views/personProfile/types'

export default defineComponent({
  name: 'BoardingEquipment',
  components: {
    AddEditEquipmentDialog: () =>
      import('@/views/administration/views/equipment/components/AddEditEquipmentDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonFilesDialog: () => import('@/components/common/CommonFilesDialog.vue'),
  },
  props: {
    equipmentList: {
      type: Array as PropType<EquipmentOutput[]>,
      required: true,
    },
    boardingType: {
      type: String as PropType<keyof typeof BOARDING_TYPE>,
      required: true,
    },
    needReload: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const activeEquipment = ref<EquipmentOutput | null>({ type: props.boardingType } as EquipmentOutput)

    const isAddEditEquipmentDialogOpen = ref(false)

    const isFilesDialogOpen = ref(false)

    const isEquipmentEditMode = ref(false)

    function onAddEquipment(): void {
      activeEquipment.value = null
      isAddEditEquipmentDialogOpen.value = true
    }

    function onEditEquipment(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isAddEditEquipmentDialogOpen.value = true

      isEquipmentEditMode.value = true
    }

    function onClickFiles(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isFilesDialogOpen.value = true
    }

    const isDeleteEquipmentDialogOpen = ref(false)

    function onClickDeleteEquipment(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isDeleteEquipmentDialogOpen.value = true
    }

    const { deleteEquipment } = useDeleteEquipment()

    async function onDeleteEquipment(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteEquipment(activeEquipment.value!.id)

        isDeleteEquipmentDialogOpen.value = false

        activeEquipment.value = { type: props.boardingType } as EquipmentOutput

        addNotification({
          text: root.$t('persons.profile.tabMenu.equipment.delete.success') as string,
          type: 'success',
          timeout: 3000,
        })

        onReloadEquipmentList()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    async function onReloadEquipmentList(): Promise<void> {
      emit('reload')
    }

    const { updateEquipment } = useUpdateEquipment()

    const { currentUser } = useAuthGetters()

    function hasEquipmentInventoryItem(equipment: EquipmentOutput): boolean {
      return Boolean(equipment.inventoryItem)
    }

    function nullRedundantEquipmentInfos(equipment: EquipmentOutput): EquipmentOutput {
      return {
        ...equipment,
        name: null,
        type: null,
        serialNumber: null,
        description: null,
        plannedReturn: null,
        actualReturn: null,
        actualReturnBy: null,
      }
    }

    async function onToggleEquipmentIssued(
      equipment: EquipmentOutput,
      isOnboarding: boolean,
      isChecked: boolean
    ): Promise<void> {
      Object.entries(equipment as EquipmentOutput).forEach(
        ([key, value]) => value && value.id && (equipment[key] = value.id)
      )

      const updatedEquipment = hasEquipmentInventoryItem(equipment)
        ? nullRedundantEquipmentInfos(equipment)
        : equipment

      try {
        await updateEquipment(updatedEquipment.id, {
          ...updatedEquipment,
          [isOnboarding ? 'issuedOn' : 'actualReturn']: isChecked ? new Date().toISOString().split('T')[0] : null,
          [isOnboarding ? 'issuedBy' : 'actualReturnBy']: isChecked ? currentUser.value.id : null,
        })

        addNotification({
          text: root.$t('persons.profile.tabMenu.equipment.update.success') as string,
          type: 'success',
          timeout: 3000,
        })

        onReloadEquipmentList()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const equipments = computed(() => props.equipmentList)

    const {
      isOpen: isNotesDialogOpen,
      notes,
      onClickOpenDialog: onClickOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<EquipmentOutput>(equipments, onReloadEquipmentList)

    const {
      isOpen: isTasksDialogOpen,
      tasks,
      onClickOpenDialog: onClickOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<EquipmentOutput>(equipments, onReloadEquipmentList)

    function onCloseAddEditDeleteInfoFilesDialog(): void {
      isAddEditEquipmentDialogOpen.value = false

      isDeleteEquipmentDialogOpen.value = false

      isFilesDialogOpen.value = false

      isEquipmentEditMode.value = false

      activeEquipment.value = { type: props.boardingType } as EquipmentOutput
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiDelete,
        mdiTextBoxCheckOutline,
        mdiNotebookEditOutline,
        mdiPaperclip,
      },
      constants: {
        Rights,

        BOARDING_TYPE,

        ENTITY_NAME,
      },
      state: {
        notesEntityId,
        tasksEntityId,

        activeEquipment,
        isAddEditEquipmentDialogOpen,
        isEquipmentEditMode,
        isDeleteEquipmentDialogOpen,

        notes,
        isNotesDialogOpen,
        isTasksDialogOpen,
        tasks,

        isFilesDialogOpen,
      },
      listeners: {
        onAddEquipment,
        onEditEquipment,
        onClickDeleteEquipment,
        onDeleteEquipment,
        onToggleEquipmentIssued,
        onReloadEquipmentList,

        onReloadNotes,
        onClickOpenNotesDialog,
        onReloadTasks,
        onClickOpenTasksDialog,

        onCloseAddEditDeleteInfoFilesDialog,
        onClickFiles,
      },
      functions: {
        dateDashNotationToDot,

        hasSufficientRights,
      },
    })
  },
})
