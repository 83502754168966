var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
    staticClass: "mb-2 rounded-lg",
    attrs: {
      "disabled": _vm.boardingType !== _vm.constants.BOARDING_TYPE.ONBOARDING,
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onAddEquipment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.addEquipment')) + " ")]) : _vm._e(), _c('v-expansion-panels', {
    staticStyle: {
      "border": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "accordion": "",
      "flat": ""
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, [_c('v-expansion-panel', {
    attrs: {
      "readonly": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "cursor-default",
    attrs: {
      "hide-actions": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.equipment')) + " ")]), _vm.boardingType === _vm.constants.BOARDING_TYPE.ONBOARDING ? _c('v-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.issuedOn')) + " ")]) : _c('v-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.actualReturn')) + " ")]), _c('v-col'), _vm.state.isAddEditEquipmentDialogOpen ? _c('AddEditEquipmentDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEquipmentEditMode,
      "equipment-to-edit": _vm.state.activeEquipment
    },
    on: {
      "reload-equipment-list": function reloadEquipmentList($event) {
        return _vm.listeners.onReloadEquipmentList();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditEquipmentDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditEquipmentDialogOpen", $$v);
      },
      expression: "state.isAddEditEquipmentDialogOpen"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._l(_vm.equipmentList, function (equipment) {
    return _c('v-expansion-panel', {
      key: equipment.id
    }, [_c('v-expansion-panel-header', [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('v-checkbox', {
      staticClass: "mt-0 pt-0",
      attrs: {
        "input-value": _vm.boardingType === _vm.constants.BOARDING_TYPE.ONBOARDING ? Boolean(equipment.issuedOn) : Boolean(equipment.actualReturn),
        "hide-details": "",
        "disabled": !_vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_UPDATE)
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        },
        "change": function change($event) {
          return _vm.listeners.onToggleEquipmentIssued(equipment, _vm.boardingType === _vm.constants.BOARDING_TYPE.ONBOARDING, $event);
        }
      }
    })], 1), _c('v-col', [_vm._v(" " + _vm._s(equipment.name) + " ")]), _vm.boardingType === _vm.constants.BOARDING_TYPE.ONBOARDING ? _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(equipment.issuedOn)) + " ")]) : _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(equipment.actualReturn)) + " ")]), _c('v-col', {
      staticClass: "text-right"
    }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_UPDATE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onEditEquipment(equipment);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onClickFiles(equipment);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPaperclip) + " ")])], 1), _vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_DELETE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onClickDeleteEquipment(equipment);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel-content', {
      staticClass: "grey lighten-3"
    }, [_c('v-row', {
      staticClass: "mt-2"
    }, [_c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.serialNumber')) + " ")]), _c('br'), equipment.serialNumber ? _c('span', [_vm._v(" " + _vm._s(equipment.serialNumber) + " ")]) : _c('span', [_vm._v("-")])]), _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.inventoryItemId')) + " ")]), _c('br'), equipment.inventoryItem ? _c('span', [_vm._v(" " + _vm._s(equipment.inventoryItem.id) + " ")]) : _c('span', [_vm._v("-")])]), _vm.boardingType === _vm.constants.BOARDING_TYPE.ONBOARDING ? _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.issuedBy')) + " ")]), _c('br'), equipment.issuedBy ? _c('span', [_vm._v(" " + _vm._s(equipment.issuedBy.name) + " ")]) : _c('span', [_vm._v("-")])]) : _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.actualReturn')) + " ")]), _c('br'), equipment.actualReturn ? _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(equipment.actualReturn)) + " ")]) : _c('span', [_vm._v("-")])]), _vm.boardingType === _vm.constants.BOARDING_TYPE.OFFBOARDING ? _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.actualReturnBy')) + " ")]), _c('br'), equipment.actualReturnBy ? _c('span', [_vm._v(" " + _vm._s(equipment.actualReturnBy.name) + " ")]) : _c('span', [_vm._v("-")])]) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_OWN) ? _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.notes')) + " ")]), _c('br'), _c('span', {
      staticClass: "d-flex align-center"
    }, [_vm._v(" " + _vm._s(equipment.notesCount) + " "), _c('v-btn', {
      staticClass: "ml-1",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickOpenNotesDialog(equipment);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiNotebookEditOutline) + " ")])], 1)], 1)]) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_OWN) ? _c('v-col', [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.equipmentList.table.col.title.tasks')) + " ")]), _c('br'), _c('span', {
      staticClass: "d-flex align-center"
    }, [_vm._v(" " + _vm._s(equipment.tasksCount) + " "), _c('v-btn', {
      staticClass: "ml-1",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickOpenTasksDialog(equipment);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiTextBoxCheckOutline) + " ")])], 1)], 1)]) : _vm._e()], 1)], 1)], 1);
  })], 2)], 1), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.notes,
      "entity-name": _vm.constants.ENTITY_NAME.EQUIPMENT,
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.tasks,
      "entity-name": _vm.constants.ENTITY_NAME.EQUIPMENT,
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isFilesDialogOpen ? _c('CommonFilesDialog', {
    attrs: {
      "entity": _vm.state.activeEquipment,
      "entity-id": _vm.state.activeEquipment.id,
      "has-download-right": _vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.EQUIPMENT_READ_OWN),
      "entity-name": "EQUIPMENT"
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.onReloadEquipmentList();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isFilesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFilesDialogOpen", $$v);
      },
      expression: "state.isFilesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteEquipmentDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteEquipment();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteEquipmentDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteEquipmentDialogOpen", $$v);
      },
      expression: "state.isDeleteEquipmentDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.equipment.delete.dialog.text')) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }